import React, { useEffect, useState } from 'react';
import { header } from '../Utility/Header';
import HeaderButton from '../Components/Button/HeaderButton';
import SilverideaLogo from '../Components/SilverideaLogo/SilverideaLogo';

const Header = () => {
    const [loaded, setLoaded] = useState(true);
    const [isHeaderFixed, setIsHeaderFixed] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    //const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
    useEffect(() => {
      const handleLoad = () => {
        setLoaded(false);
        const loader = document.querySelector(".zx-loader");
        if (loader) {
            (loader as any).style.opacity = "0";
        }
      };
      window.addEventListener("load", handleLoad);
      setTimeout(() => {
        setLoaded(false);
      });
      return () => {
        window.removeEventListener("load", handleLoad);
      };
    }, []);
  
    useEffect(() => {
      const handleScroll = () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll > 50) {
          setIsHeaderFixed(true);
        } else {
          setIsHeaderFixed(false);
        }
      };
  
      window.addEventListener("scroll", handleScroll);
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
  
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  
    // const toggleDropdownMenu = () => {
    //   setIsDropdownOpen(!isDropdownOpen);
    // };
  
    const closeMenu = () => {
      setIsMenuOpen(false);
    };
  
  
  
    return (
      <>
  
        {loaded && (
          <div className="zx-loader">
            <span className="loader"></span>
          </div>
        )}
        <header className={`bg-market  ${isHeaderFixed  ? "header-fixed" : ""}`}>
          <div className="container">
            <div className="row">
              <nav className="navbar navbar-expand-lg">
                <a className="navbar-brand" href="/">
                  {/*
                  <img src="assets/img/logo/logo.png" alt="logo" className="dq-brand" />
                  <img src="assets/img/logo/logo-footer.png" alt="footer" className="dark-mode" />*/}
                  <SilverideaLogo />
                </a>
                <button onClick={toggleMenu} className="navbar-toggler zx-site-menu-icon" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                  aria-expanded="false" aria-label="Toggle navigation">
                  <i className="fa-solid fa-bars"></i>
                </button>
                <div className="zx-main-menu">
                  <ul>
                    {/* <li className="dropdown drop-list">
                      <NavLink className="dropdown-arrow" to={''}>Home<i className="fa-solid fa-angle-down"></i></NavLink>
                      <ul className="sub-menu">
                        <li><NavLink to="/software">Software Landing</NavLink></li>
                        <li><NavLink to="/">App Landing</NavLink></li>
                      </ul>
                    </li> */}
                    {header.map((data, index) => (
                      <li key={index} className="non-drop">
                        <a href={data.href} className="nav-link">{data.title}</a>
                      </li>
                    ))}
                  </ul>
                </div>
                <HeaderButton />
              </nav>
            </div>
          </div>
        </header>
  
  
        <div className={`zx-sidebar-overlay ${isMenuOpen ? 'show' : ''}`} style={{ display: isMenuOpen ? "block" : "none" }} ></div>
        <div id="zx-mobile-menu" className={`zx-mobile-menu ${isMenuOpen ? 'zx-menu-open' : ''}`}>
          <div className="zx-menu-title">
            <span className="menu_title">Menu</span>
            <button onClick={closeMenu} className="zx-close-menu">×</button>
          </div>
          <div className="zx-menu-inner">
            <div className="zx-menu-content">
              <ul>
                {/* <li className={`dropdown drop-list ${isDropdownOpen ? 'active' : ''}`}>
                  <NavLink to={''}  onClick={toggleDropdownMenu} className="dropdown-arrow">Home</NavLink>
                  <ul style={{ display: isDropdownOpen ? "block" : "none" }} className="sub-menu">
                    <li><NavLink onClick={closeMenu} to="/" >Software Landing</NavLink></li>
                    <li><NavLink to="/app" >App Landing</NavLink></li>
                  </ul>
                </li> */}
                {header.map((data, index) => (
                  <li key={index} className="non-drop">
                     <a onClick={closeMenu} href={data.href} className="nav-link">{data.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
  
      </>
    )
}

export default Header;